.container {
  display: flex;
}
.service {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: 10px;
}
.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.service-name-container {
  position: absolute;
  top: 50%;
  background-color: #494949;
  opacity: 0.8;
  left: 0;
  width: 70%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-img {
  width: 70vw;
  cursor: pointer;
}

.service-name {
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0px;
}
.service-img:hover {
  transform: scale(1.05);
  border-radius: 15px;
}

.middle-side {
  background-image: url(../../assets/bg3.jpg);
  background-repeat: repeat-y;
  background-position: center;
  background-size: contain;
  width: 70vw;
  margin-top: 55px;
}
.left-side,
.right-side {
  background-image: url(../../assets/bubbles.jpg);
  width: 15vw;
  background-position: center;
  background-repeat: repeat-y;
}
.mobile-contact {
  position: fixed;
  bottom: 5%;
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #494949;
  cursor: pointer;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  position: fixed;
}

.mobile-contact:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.mobile-contact:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #494949;
  left: 0;
  top: 0;
  border-radius: 10px;
}
.mobile {
  color: white;
  border: none;
  background: #494949;
  margin-left: 10px;
}
@media (min-width: 680px) {
  .service {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 15px;
    align-items: center;
    justify-content: center;
  }
  .service-img {
    width: 440px;
    height: 350px;
  }
  .middle-side {
    background-image: unset;
    background-color: aquamarine;
  }
}
