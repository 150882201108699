.footer {
  display: flex;
  justify-content: space-around;
  background-color: #494949;
  column-gap: 20px;
  padding: 40px 15vw;
}
.icons {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap-reverse;
  row-gap: 10px;
}
.social-icon {
  width: 25px;
  height: 25px;
}
.nontext-icon {
  display: flex;
  column-gap: 15px;
  row-gap: 10px;
}
.text-icon {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.number {
  color: white;
}
.icon-number {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.logo-bottom {
  width: 90px;
}
@media (min-width: 680px) {
  .logo-bottom {
    width: 105px;
  }
}
