.contact-container {
  padding: 90px 0px;
}
.social-networks {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;
  align-items: center;
}
.social-network {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.click {
  font-size: 13px;
  transform: translate(-7px, -14px);
  animation: scaling 3000ms infinite;
}
@keyframes scaling {
  from {
    color: red;
    transform: translateY(-10px);
  }
  to {
    color: green;
    transform: translateY(-7px);
  }
}
