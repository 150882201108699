.header {
  background-color: #494949;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 2;
  width: 100%;
  padding: 5px 15vw;
}
a {
  text-decoration: none;
}
.company-hd-logo img {
  width: 90px;
}
.ul-lists {
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;
}
li {
  list-style: none;
}
.burger-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}
.lines-container {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.line {
  height: 2px;
  background-color: white;
  width: 20px;
}
.top-line {
  width: 10px;
}
.bottom-line {
  width: 10px;
  align-self: flex-end;
}
.burger-items-container {
  position: absolute;
  top: 50px;
  width: 150px;
  right: 0;
  height: 100vh;
  z-index: 1;
  background-color: #494949;
  opacity: 0.9;
}
.burger-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  padding-top: 20px;
  color: white;
  font-size: 20px;
}
.burger-items li:hover {
  cursor: pointer;
  color: pink;
}
.ul-lists li:hover {
  cursor: pointer;
  color: pink;
  transform: scale(1.2);
}
.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}
.language-switcher {
  padding: 5px;
  border-radius: 10px;
  background-color: #d0bdf4;
}
option {
  border-radius: 10px;
}
@media (min-width: 680px) {
  .ul-lists {
    display: flex;
    column-gap: 15px;
  }
  .burger-menu {
    display: none;
  }
  .header {
    justify-content: space-around;
  }
  .navigation {
    width: 45vw;
  }
  .ul-lists li {
    cursor: pointer;
    color: white;
  }
  .company-hd-logo img {
    width: 105px;
  }
}
